import { A, cache, createAsync, useNavigate } from "@solidjs/router";
import {
  Accessor,
  createEffect,
  createSignal,
  For,
  Match,
  onCleanup,
  onMount,
  Show,
  Suspense,
  Switch,
} from "solid-js";
import { arrowLeftIcon, threeDotsVerticalIcon } from "~/assets/assets";
import { config } from "~/data/config";
import { clientRepo } from "~/server/apis/client_repo";
import {
  getSquidPurchaseRouteData,
  SquidPurchaseRouteData,
} from "~/server/data/squid_purchase_route_data";
import { showSnackBar } from "~/shared_states/snackbar";
import { Optional } from "~/types";
import { toIndianNumber } from "~/utils/number";
import { EmptyState } from "~/widgets/empty_state";
import { PhosphorIcon } from "~/widgets/icons";
import { CircularLoader, DottedLoader } from "~/widgets/loader";
import { Tooltip } from "~/widgets/tooltip";

const getSquidPurchaseRouteData$C = cache(
  getSquidPurchaseRouteData,
  "squid_purchase"
);

export default function Purchases() {
  const routeData: Accessor<SquidPurchaseRouteData | undefined> =
    createAsync<SquidPurchaseRouteData>(() => {
      return getSquidPurchaseRouteData$C();
    });

  const [loading, setLoading] = createSignal(false);
  const [activeRow, setActiveRow] = createSignal<string>("");
  const navigator = useNavigate();
  const [downloadingOrders, setDownloadingOrders] = createSignal<string[]>([]);

  // ===================================================================
  // Setup to handle clicks on the screen and close any active overlays
  // ===================================================================
  const [closeOverlaysNonce, setCloseOverlaysNonce] = createSignal<number>(1);

  const handleClickOutside = (event: MouseEvent) => {
    const overlayTriggerAreas = document.querySelectorAll(".overlay-trigger");
    const isTriggeringOverlay = Array.from(overlayTriggerAreas).some(
      (overlayTriggerArea) => overlayTriggerArea.contains(event.target as Node)
    );
    if (isTriggeringOverlay) return;

    const overlays = document.querySelectorAll(".overlay");
    const isAnyOverlayOpen = overlays.length > 0;
    const isClickInsideOverlay = Array.from(overlays).some((overlay) =>
      overlay.contains(event.target as Node)
    );

    if (!isClickInsideOverlay && isAnyOverlayOpen) {
      setCloseOverlaysNonce(closeOverlaysNonce() + 1);
      event.stopPropagation();
    }
  };

  onMount(() => {
    document.addEventListener("click", handleClickOutside);
    onCleanup(() => {
      document.removeEventListener("click", handleClickOutside);
    });
  });
  // ===================================================================
  // End - Setup to handle clicks on the screen and close any active overlays
  // ===================================================================

  const downloadOrder = async (orderId: string) => {
    setLoading(true);
    setDownloadingOrders((prev) => [...prev, orderId]);
    try {
      const response = await clientRepo.downloadSquidOrder(orderId);
      if (response) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `hubble_procurement_order_${orderId}.xlsx`;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Failed to download the order:");
      }
    } catch (error) {
      console.error("An error occurred while downloading the order:", error);
    } finally {
      setDownloadingOrders((prev) => prev.filter((id) => id !== orderId));
      setLoading(false);
    }
  };

  const [sendingEmail, setSendingEmail] = createSignal<string[]>([]);

  const sendInvoiceEmail = async (props: { purchaseId: string }) => {
    setSendingEmail((prev) => [...prev, props.purchaseId]);
    try {
      await clientRepo.sendInvoiceOverEmail({ purchaseId: props.purchaseId });
      showSnackBar({
        level: "success",
        message: "email sent to your registered email-id",
      });
    } catch (e: any) {
      showSnackBar({
        level: "error",
        message: e.message,
      });
    }
    setSendingEmail((prev) => prev.filter((id) => id !== props.purchaseId));
  };

  return (
    <Suspense
      fallback={
        <div class="flex-1 items-center justify-center">
          <DottedLoader color="#999" from="squid purchases suspense" />
        </div>
      }
    >
      <div class="flex-1 items-center justify-center">
        <div class="fixed z-10 flex h-[52px] w-full items-center border border-b  border-baseTertiaryMedium bg-white py-4 pl-1 pr-5 text-bold text-textDark sm:sticky lg:h-auto lg:px-5 lg:py-3 lg:text-h5">
          <img
            src={arrowLeftIcon}
            class="cursor-pointer px-2.5 lg:hidden"
            alt="back"
            onClick={() => navigator(-1)}
          />
          My purchases
        </div>
        <div
          class="mt-12 flex flex-col items-center justify-center gap-3 bg-baseTertiaryMedium p-4 lg:hidden"
          classList={{
            "h-auto":
              routeData()?.purchaseOrders?.data &&
              routeData()!.purchaseOrders?.data.length > 0,
            "h-[92%]":
              routeData()?.purchaseOrders?.data &&
              routeData()!.purchaseOrders?.data.length <= 0,
          }}
        >
          <Show
            when={
              routeData()?.purchaseOrders?.data &&
              routeData()!.purchaseOrders?.data.length > 0
            }
            fallback={emptyState()}
          >
            <PurchaseCards closeOverlaysNonce={closeOverlaysNonce} />
          </Show>
        </div>
        <div class="hidden lg:block">
          <Show
            when={[
              routeData()?.purchaseOrders?.data &&
                routeData()!.purchaseOrders?.data.length > 0,
            ]}
            fallback={emptyState()}
          >
            {PurchaseTable()}
          </Show>
        </div>
      </div>
    </Suspense>
  );

  function PurchaseCards(props: { closeOverlaysNonce: Accessor<number> }) {
    const [overlayOpenForInvoiceId, setOverlayOpenForInvoiceId] =
      createSignal<Optional<string>>(null);

    createEffect(() => {
      if (props.closeOverlaysNonce()) {
        setOverlayOpenForInvoiceId(null);
      }
    });

    const toggleDropdown = (invoiceId: string) => {
      setOverlayOpenForInvoiceId(invoiceId);
    };

    return (
      <For each={routeData()?.purchaseOrders.data}>
        {(purchase) => {
          return (
            <div class="relative w-[343px] rounded-xl border border-baseTertiaryDark bg-white p-4">
              <Show
                when={
                  purchase.status === "FULFILLED" ||
                  purchase.status === "PARTIALLY_FULFILLED"
                }
              >
                <div
                  class="overlay-trigger absolute right-1 top-1 flex h-[37px] w-[37px] cursor-pointer items-center justify-center rounded-full"
                  classList={{
                    "bg-baseTertiaryMedium":
                      overlayOpenForInvoiceId() === purchase.id,
                  }}
                  onClick={() => toggleDropdown(purchase.id)}
                >
                  <img src={threeDotsVerticalIcon} alt="" />
                </div>
              </Show>
              <Show when={overlayOpenForInvoiceId() === purchase.id}>
                <div class="overlay absolute right-0 top-11 h-[55px] w-[184px] rounded-lg border border-baseTertiaryDark bg-white py-2 [box-shadow:0px_2px_20px_0px_rgba(0,_0,_0,_0.10)]">
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      if (sendingEmail().includes(purchase.id)) {
                        return;
                      }
                      sendInvoiceEmail({
                        purchaseId: purchase.id,
                      });
                    }}
                    class="flex h-[39px] items-center px-4 text-mediumBold text-textDark"
                  >
                    Send invoice to mail
                  </div>
                </div>
              </Show>
              <div class="flex flex-col gap-2">
                <div class="text-mediumBold text-textDark">
                  {new Date(purchase.transactedAt).toLocaleDateString("en-IN", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })}
                </div>
                <div class="text-medium text-textDark">
                  {purchase.brands.length > 3
                    ? purchase.brands.slice(0, 3).join(", ").toString() +
                      " +" +
                      (purchase.brands.length - 3) +
                      " more"
                    : purchase.brands.join(", ")}
                </div>
              </div>
              <hr class="my-4 w-full border-t border-baseTertiaryMedium" />
              <div class=" flex flex-col gap-3">
                <div class="flex justify-between">
                  <p class="text-mediumBold text-textDark">Total amount</p>
                  <p class="text-medium text-textDark">
                    ₹{toIndianNumber(purchase.totalAmount)}
                  </p>
                </div>
                <Switch>
                  <Match when={purchase.status === "PROCESSING"}>
                    <div class="flex h-9 w-full items-center justify-center gap-2 rounded-lg  bg-baseTertiaryMedium px-4 py-2.5 text-mediumBold text-textNormal">
                      <CircularLoader size={18} borderSize={2} />
                      Generating
                    </div>
                  </Match>
                  <Match when={purchase.status === "FAILED"}>
                    <div class="flex h-9 w-full items-center justify-center gap-2 rounded-lg bg-[#FFEFD6] px-4 py-2.5 text-mediumBold text-errorDark">
                      <PhosphorIcon
                        name="warning"
                        fontSize={18}
                        class="text-errorDark"
                        size="bold"
                      />
                      Failed
                    </div>
                  </Match>
                  <Match when={true}>
                    <div class="flex gap-3">
                      <A
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        href={config.receiptUrl + "/invoice/" + purchase.id}
                        target="_blank"
                        class="flex h-9 w-full items-center justify-center gap-1 rounded-lg border border-basePrimaryDark px-4 py-2.5 text-mediumBold text-textDark"
                      >
                        Invoice
                      </A>
                      <button
                        onClick={(event) => {
                          if (
                            (purchase.status === "FULFILLED" ||
                              purchase.status === "PARTIALLY_FULFILLED") &&
                            !loading()
                          ) {
                            downloadOrder(purchase.id);
                          }
                          event.stopPropagation();
                        }}
                        class="flex h-9 w-full items-center justify-center gap-1 rounded-lg bg-basePrimaryDark px-4 py-2.5 text-mediumBold text-white"
                      >
                        <Show
                          when={
                            loading() &&
                            downloadingOrders().includes(purchase.id)
                          }
                          fallback={"Download CSV"}
                        >
                          <DottedLoader color="#fff" />
                        </Show>
                      </button>
                    </div>
                  </Match>
                </Switch>
              </div>
            </div>
          );
        }}
      </For>
    );
  }

  function PurchaseTable() {
    return (
      <div class="h-[90vh] overflow-auto p-4 pb-10">
        <div class="shadow rounded-lg border border-baseTertiaryMedium bg-white">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="sticky -top-5 z-10 rounded-full  bg-gray-50">
              <tr>
                <th class="rounded-tl-lg border-r  px-3 py-2 text-left text-smallBold text-textDark">
                  DATE
                </th>
                <th class="border-r px-3  py-2 text-left text-smallBold text-textDark">
                  AMOUNT
                </th>
                <th class="border-r px-3  py-2 text-left text-smallBold text-textDark">
                  COUNT
                </th>
                <th class="border-r px-3  py-2 text-left text-smallBold text-textDark">
                  NOTES
                </th>
                <th class="border-r px-3  py-2 text-left text-smallBold text-textDark">
                  BRANDS
                </th>
                <th class="rounded-tr-lg px-3  py-2 text-left text-smallBold text-textDark">
                  CSV
                </th>
              </tr>
            </thead>
            <tbody class=" divide-y divide-gray-200  rounded-full bg-white ">
              {routeData()?.purchaseOrders.data.map((purchase) => (
                <tr
                  class={` text-medium last:rounded-b-lg  hover:cursor-pointer hover:bg-baseTertiaryMedium   ${purchase.status === "FAILED" ? "text-textNormal" : "text-textDark"}`}
                  onClick={() => {
                    if (
                      purchase.status === "FULFILLED" ||
                      purchase.status === "PARTIALLY_FULFILLED"
                    ) {
                      navigator("/purchases/" + purchase.id, {
                        state: {
                          fromPurchasesPage: true,
                        },
                      });
                    } else {
                      navigator("/order/" + purchase.id, {
                        state: {
                          fromPurchasesPage: true,
                        },
                      });
                    }
                  }}
                  onMouseEnter={() => {
                    setActiveRow(purchase.id);
                  }}
                  onMouseLeave={() => {
                    setActiveRow("");
                  }}
                >
                  <td class="whitespace-nowrap border-r p-3 text-mediumBold last:rounded-bl-lg">
                    {new Date(purchase.transactedAt).toLocaleDateString(
                      "en-IN",
                      {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      }
                    )}
                  </td>
                  <td class="whitespace-nowrap border-r p-3">
                    ₹{toIndianNumber(purchase.totalAmount)}
                  </td>
                  <td class="whitespace-nowrap border-r p-3">
                    {purchase.voucherCount}
                  </td>
                  <td class="whitespace-nowrap border-r p-3">
                    <div class=" w-full">
                      <Show
                        when={purchase.notes && purchase.notes?.length > 0}
                        fallback={<div>{"-"}</div>}
                      >
                        <Tooltip
                          parent={
                            <div class=" max-w-[132px] overflow-hidden text-ellipsis">
                              {purchase.notes}
                            </div>
                          }
                          tooltip={
                            <div class="mt-2 rounded bg-black px-2 py-1 text-textWhite">
                              {purchase.notes}
                            </div>
                          }
                        />
                      </Show>
                    </div>
                  </td>

                  <td class=" whitespace-nowrap border-r p-3">
                    <div class="flex items-center justify-center">
                      <div class="grow">
                        {purchase.brands.length > 4
                          ? purchase.brands.slice(0, 4).join(", ").toString() +
                            " +" +
                            (purchase.brands.length - 4) +
                            " more"
                          : purchase.brands.join(", ")}
                      </div>
                      <Show
                        when={
                          activeRow() === purchase.id &&
                          (purchase.status === "FULFILLED" ||
                            purchase.status === "PARTIALLY_FULFILLED")
                        }
                      >
                        <div class="flex gap-2">
                          <Tooltip
                            parent={
                              <div
                                class="flex h-7 w-7 cursor-pointer items-center justify-center rounded-[8px] border border-baseTertiaryDark bg-white"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  if (sendingEmail().includes(purchase.id)) {
                                    return;
                                  }
                                  sendInvoiceEmail({
                                    purchaseId: purchase.id,
                                  });
                                }}
                              >
                                <Show
                                  when={sendingEmail().includes(purchase.id)}
                                  fallback={
                                    <PhosphorIcon
                                      name="envelope"
                                      fontSize={20}
                                    />
                                  }
                                >
                                  <CircularLoader size={18} />
                                </Show>
                              </div>
                            }
                            tooltip={
                              <div class="mt-1 rounded bg-black px-2 py-1  text-medium text-white">
                                {" "}
                                Send invoice over mail
                              </div>
                            }
                          />
                          <Tooltip
                            parent={
                              <A
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                                href={
                                  config.receiptUrl + "/invoice/" + purchase.id
                                }
                                target="_blank"
                              >
                                <div class="flex h-7 w-7 items-center justify-center rounded-[8px] border border-baseTertiaryDark bg-white">
                                  <PhosphorIcon name="file-pdf" fontSize={20} />
                                </div>
                              </A>
                            }
                            tooltip={
                              <div class="tooltip mt-1 rounded bg-black px-2 py-1  text-medium text-white">
                                {" "}
                                View invoice
                              </div>
                            }
                          />
                        </div>
                      </Show>
                    </div>
                  </td>
                  <td class="whitespace-nowrap p-3 ">
                    <div
                      class="cursor-pointer p-1"
                      onClick={(event) => {
                        event.stopPropagation();
                        if (purchase.status === "FULFILLED" && !loading()) {
                          downloadOrder(purchase.id);
                        }
                      }}
                    >
                      <PhosphorIcon
                        name={
                          purchase.status === "PROCESSING"
                            ? "circle-notch"
                            : purchase.status === "FAILED"
                              ? "warning"
                              : "cloud-arrow-down"
                        }
                        fontSize={20}
                        class={`${purchase.status === "FAILED" ? "text-errorDark" : "text-baseSecondaryLight hover:text-black"}`}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function emptyState() {
    return (
      <EmptyState
        primaryText="Gifts cards you purchase will appear here"
        secondaryText="No gift cards purchased yet"
      />
    );
  }
}
